@font-face {
  font-family: 'icomoon';
  src: url('fonts/icomoon.eot?fx1c0k');
  src:
    url('fonts/icomoon.eot?fx1c0k#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?fx1c0k') format('truetype'),
    url('fonts/icomoon.woff?fx1c0k') format('woff'),
    url('fonts/icomoon.svg?fx1c0k#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-accident_1:before {
  content: '\e900';
}
.icon-bike_1:before {
  content: '\e901';
}
.icon-bike_2:before {
  content: '\e902';
}
.icon-car_1:before {
  content: '\e903';
}
.icon-compass_1:before {
  content: '\e904';
}
.icon-gavel_1:before {
  content: '\e905';
}
.icon-guaranteed_1:before {
  content: '\e906';
}
.icon-health_1:before {
  content: '\e907';
}
.icon-hospital_bed_1:before {
  content: '\e908';
}
.icon-house_1:before {
  content: '\e909';
}
.icon-life_insurance_1:before {
  content: '\e90a';
}
.icon-living_room-_1:before {
  content: '\e90b';
}
.icon-motorbike_1:before {
  content: '\e90c';
}
.icon-retired_1:before {
  content: '\e90d';
}
.icon-scooter_1:before {
  content: '\e90e';
}
.icon-sun_umbrella_1:before {
  content: '\e90f';
}
.icon-vacuum_1:before {
  content: '\e910';
}
.icon-infos:before {
  content: '\e911';
}
.icon-fremdsprache:before {
  content: '\e912';
}
.icon-veranstalter:before {
  content: '\e913';
}
.icon-transport:before {
  content: '\e914';
}
.icon-sparte:before {
  content: '\e915';
}
.icon-betrieb:before {
  content: '\e916';
}
.icon-finanz:before {
  content: '\e917';
}
.icon-vorsorge:before {
  content: '\e918';
}
.icon-jagd-vers:before {
  content: '\e919';
}
.icon-transport-vers:before {
  content: '\e91a';
}
.icon-download:before {
  content: '\e91b';
}
.icon-press:before {
  content: '\e91c';
}
.icon-karte:before {
  content: '\e91d';
}
.icon-vergleich:before {
  content: '\e91e';
}
.icon-sms:before {
  content: '\e91f';
}
.icon-anregungen:before {
  content: '\e920';
}
.icon-kundendaten:before {
  content: '\e921';
}
.icon-greencare:before {
  content: '\e922';
}
.icon-landwirt:before {
  content: '\e923';
}
.icon-betriebs-haft:before {
  content: '\e924';
}
.icon-phone:before {
  content: '\e925';
}
.icon-portal:before {
  content: '\e926';
}
.icon-alert:before {
  content: '\e927';
}
.icon-pin:before {
  content: '\e928';
}
.icon-kundenberater:before {
  content: '\e929';
}
.icon-magnifier:before {
  content: '\e92a';
}
.icon-dropdown-arrow:before {
  content: '\e92b';
}
.icon-type-mov:before {
  content: '\e92c';
}
.icon-email:before {
  content: '\e92d';
}
.icon-type-avi:before {
  content: '\e92e';
}
.icon-type-file:before {
  content: '\e92f';
}
.icon-type-jpg:before {
  content: '\e930';
}
.icon-type-mp3:before {
  content: '\e931';
}
.icon-type-pdf:before {
  content: '\e932';
}
.icon-type-png:before {
  content: '\e933';
}
.icon-mail:before {
  content: '\e934';
}
.icon-checkbox:before {
  content: '\e935';
}
.icon-question:before {
  content: '\e936';
}
.icon-type-mp4:before {
  content: '\e937';
}
.icon-anfrage:before {
  content: '\e938';
}
.icon-unfall-vers:before {
  content: '\e939';
}
.icon-reise-vers:before {
  content: '\e93a';
}
.icon-rechtschutz:before {
  content: '\e93b';
}
.icon-haushalt-vers:before {
  content: '\e93c';
}
.icon-kfz-vers:before {
  content: '\e93d';
}
.icon-fahrrad:before {
  content: '\e93e';
}
.icon-jugend-vers:before {
  content: '\e93f';
}
.icon-nav-arrow:before {
  content: '\e940';
}
.icon-fax:before {
  content: '\e941';
}
.icon-portfolio:before {
  content: '\e942';
}
.icon-building:before {
  content: '\e943';
}
.icon-clock_1:before {
  content: '\e944';
}
.icon-calendar_1:before {
  content: '\e945';
}
